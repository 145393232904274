import React from "react"
import { CheckIcon, LoaderIcon, CheckCircleIcon } from "lucide-react";
import { Cpu, MemoryStickIcon } from 'lucide-react';

const WebServiceFormX = ({ indRepo, darkMode, deploying, setPlan, getResponse, successful, details, addEnvVar, plans, nameAvailability, isAvailabilityButtonDisabled, handleCheckNameAvailability, handleSubmitWebService, webServiceFormData, handleEnvVarChange, removeEnvVar, handleWebServiceFormChange, setNameAvailability, setIsAvailabilityButtonDisabled  }) => {
    return(
        <>
            <div className={`px-4 ${darkMode ? 'bg-gray-900' : ''} py-5 sm:px-6`}>
                <h3 className={`text-lg leading-6 mb-2 font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>Deploy your project</h3>
                <p className={`mb-3 mt-8 max-w-2xl text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    Configuration for {indRepo.name}
                </p>
                <div>
                    {details && (
                        <div className="flex items-center">
                            <a
                                href={`https://${details?.endpoint}`}
                                target="_blank"
                                className={`cursor-pointer hover:underline ${darkMode ? 'text-indigo-400' : 'text-indigo-600'} flex items-center`}
                            >
                                <p className={`${darkMode ? 'text-white' : 'text-black'} no-underline mr-2`}>Your endpoint: </p>
                                https://{details?.endpoint}
                            </a>
                            <CheckCircleIcon className="text-green-500 w-4 h-auto ml-2" />
                        </div>
                    )}
            </div>
        </div>
            <div className={`border-t  ${darkMode ? 'border-gray-700 bg-gray-900' : ''} px-4 py-5 sm:p-0`}>
                <form onSubmit={handleSubmitWebService}>
                    <dl className={`sm:divide-y ${darkMode ? 'sm:divide-gray-700' : ''}`}>
                        {[
                            {
                                label: "Deployment Name",
                                description: "Enter a unique name for your deployment. Check if it is available.",
                                name: "webAppName",
                                type: "text",
                                validate: (value) => {
                                    const sanitized = value.replace(/[^a-z-]/g, '');
                                    return sanitized.replace(/^[0-9]/, '');
                                },
                                transform: (value) => {
                                    return value.replace(/\s+/g, '-');
                                }
                            },
                            { label: "Repository URL", description: "This is the link from your GitHub repository", name: "repoUrl", type: "text", disabled: true },
                            { label: "Branch", description: "Specify the branch to deploy", name: "branch", type: "text" },
                            {
                                label: "Runtime",
                                description: "Select the runtime environment for your application",
                                name: "runtime",
                                type: "select",
                                options: ["Node", "Python 3"]
                            },
                            {
                                label: "Location",
                                description: "Choose the server location for your deployment",
                                name: "location",
                                type: "select",
                                labels: [
                                    "Amsterdam (Netherlands)",
                                    "Atlanta (Georgia, USA)",
                                    "New Jersey (New Jersey, USA)",
                                    "Paris (France)",
                                    "Dallas (Texas, USA)",
                                    "Frankfurt (Germany)",
                                    "Hong Kong",
                                    "Los Angeles (California, USA)",
                                    "London (United Kingdom)",
                                    "Manchester (United Kingdom)",
                                    "Miami (Florida, USA)",
                                    "Mexico City (Mexico)"
                                ],
                                options: [
                                    "ams", "atl", "ewr", "cdg", "dfw", "fra", "hkg",
                                    "lax", "lhr", "man", "mia", "mex"
                                ]
                            },
                            ...(webServiceFormData.runtime === "Node" ? [
                                {
                                    label: "Startup Command",
                                    description: "Command to start your application. Typically, it is node index.js",
                                    name: "startupCommand",
                                    type: "text"
                                },
                                {
                                    label: "Port Number",
                                    description: "The port number on which your server is running (Default is 5000)",
                                    name: "portNumber",
                                    type: "text"
                                },
                                {
                                    label: "Node version",
                                    description: "Select the version of Node for your application. You can find it by running node -v",
                                    name: "nodejsVersion",
                                    type: "select",
                                    options: ["14.x", "16.x", "18.x", "20.x", "22.x"]
                                }
                            ] : webServiceFormData.runtime === "Python 3"
                                ? [
                                    {
                                        label: "Build Command",
                                        description: "Command to build your project",
                                        name: "buildCommand",
                                        type: "text"
                                    },
                                    {
                                        label: "Start Command",
                                        description: "Command to start your application",
                                        name: "startCommand",
                                        type: "text"
                                    }
                                ]
                                : []),
                        ].map((field, index) => (
                            <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className={`text-lg font-medium ${darkMode ? 'text-gray-200' : 'text-gray-800'}`}>
                                    {field.label}
                                    {field.description && (
                                        <p className={`mt-1 text-sm font-light ${darkMode ? 'text-gray-400' : 'text-gray-700'}`}>
                                            {field.description}
                                        </p>
                                    )}
                                </dt>
                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-300' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                    <div className="flex items-center">
                                        {field.type === "select" ? (
                                            <select
                                                name={field.name}
                                                value={webServiceFormData[field.name]}
                                                onChange={handleWebServiceFormChange}
                                                className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                        ? 'bg-gray-700 border-gray-600 text-white'
                                                        : 'border-gray-300 text-gray-900'
                                                    }`}
                                            >
                                                {field.name === "location"
                                                    ? field.labels.map((label, optionIndex) => (
                                                        <option key={optionIndex} value={field.options[optionIndex]}>
                                                            {label}
                                                        </option>
                                                    ))
                                                    : field.options.map((option, optionIndex) => (
                                                        <option key={optionIndex} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                            </select>
                                        ) : (
                                            <input
                                                type={field.type}
                                                name={field.name}
                                                value={webServiceFormData[field.name]}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    if (field.validate) {
                                                        value = field.validate(value);
                                                    }
                                                    if (field.transform) {
                                                        value = field.transform(value);
                                                    }
                                                    handleWebServiceFormChange({
                                                        target: { name: field.name, value }
                                                    });

                                                    if (field.name === 'webAppName') {
                                                        const isValid = /^[a-z-]*$/.test(value);
                                                        e.target.style.borderColor = isValid ? 'initial' : 'red';
                                                        setNameAvailability(null);
                                                        setIsAvailabilityButtonDisabled(!isValid);
                                                    }
                                                }}
                                                className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${darkMode
                                                        ? 'bg-gray-700 border-gray-600 text-white'
                                                        : 'border border-gray-300'
                                                    }`}
                                                required
                                                disabled={field.disabled}
                                            />
                                        )}
                                        {field.name === 'webAppName' && (
                                            <button
                                                type="button"
                                                onClick={handleCheckNameAvailability}
                                                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                disabled={isAvailabilityButtonDisabled}
                                            >
                                                Availability
                                            </button>
                                        )}
                                    </div>
                                    {field.name === 'webAppName' && nameAvailability && (
                                        <div className={`mt-2 font-medium text-sm ${nameAvailability === 'Name available!'
                                                ? 'text-green-500'
                                                : darkMode ? 'text-red-400' : 'text-red-600'
                                            }`}>
                                            {nameAvailability}
                                        </div>
                                    )}
                                </dd>
                            </div>
                        ))}
                    </dl>
                    <div className="mt-6 sm:px-6">
                <h3 className={`text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>Environment Variables</h3>
                {webServiceFormData.envVars.map((envVar, index) => (
                    <div key={index} className="mt-2 flex items-center space-x-2">
                        <input
                            type="text"
                            placeholder="Key"
                            value={envVar.key}
                            onChange={(e) => handleEnvVarChange(index, 'key', e.target.value)}
                            className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                                darkMode 
                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                                    : 'border-gray-300 placeholder-gray-500'
                            }`}
                        />
                        <input
                            type="text"
                            placeholder="Value"
                            value={envVar.value}
                            onChange={(e) => handleEnvVarChange(index, 'value', e.target.value)}
                            className={`mt-1 block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                                darkMode 
                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400' 
                                    : 'border-gray-300 placeholder-gray-500'
                            }`}
                        />
                        <button
                            type="button"
                            onClick={() => removeEnvVar(index)}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            Remove
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={addEnvVar}
                    className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-green-500"
                >
                    Add Environment Variable
                </button>
            </div>
            <div className='grid grid-cols-1 pt-6 sm:grid-cols-3'>
                {plans?.map((element, index) => (
                    <div
                        key={index}
                        className={`py-3 px-4 mx-10 my-4 cursor-pointer border border-indigo-600 hover:border-2 ${
                            webServiceFormData.plan === element.plan 
                                ? darkMode ? 'bg-indigo-900/20' : 'bg-indigo-100'
                                : darkMode ? 'bg-gray-800' : ''
                        }`}
                        onClick={() => setPlan(element)}
                    >
                        <div className='flex justify-between items-center'>
                            <p className={`font-semibold text-lg ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                                {element?.plan}
                            </p>
                            <p className={`font-medium ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                                {element?.price}
                            </p>
                        </div>
                        <div className='flex pt-2 justify-between items-center'>
                            <p className="flex items-center text-gray-800 mb-2"><Cpu className="text-indigo-600 inline-block mr-1" />{element.cpu} CPU</p>
                            <p className="flex items-center text-gray-800 mb-2"><MemoryStickIcon className="text-indigo-600 inline-block mr-1" />{element.ram} RAM</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={`px-4 py-3 ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} text-right sm:px-6`}>
                <button
                    type="submit"
                    className={`inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                        deploying || nameAvailability !== 'Name available!'
                            ? 'bg-gray-400 cursor-not-allowed text-gray-700 border-gray-300'
                            : 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                    } ${getResponse === false && successful === false && nameAvailability === "Name available!" ? "mb-24" : ""}`}
                    disabled={deploying || nameAvailability !== 'Name available!'}
                >
                    {deploying ? 'Deploying...' : 'Deploy'}
                </button>
                {!deploying && nameAvailability !== 'Name available!' &&
                    <p className={`text-red-500 pt-2 pb-12 ${darkMode ? 'text-red-400' : 'text-red-500'}`}>
                        Check if the name is available first!
                    </p>
                }
            </div>
            {getResponse &&
                <div className='flex justify-center'>
                    <div className={`rounded py-4 mt-4 mb-24 px-12 flex justify-center items-center ${
                        darkMode ? 'bg-yellow-900/20' : 'bg-yellow-100'
                    }`}>
                        <LoaderIcon className='text-yellow-600 w-5 animate-spin h-auto mr-1' />
                        <p className={`text-sm font-semibold ${darkMode ? 'text-yellow-500' : 'text-yellow-600'}`}>
                            Deploying... Please wait.
                        </p>
                    </div>
                </div>
            }
            {successful && details &&
                <div className='flex justify-center'>
                    <div className={`rounded flex items-center py-4 px-12 mt-4 mb-24 ${
                        darkMode ? 'bg-green-900/20' : 'bg-green-100'
                    }`}>
                        <CheckIcon className='text-green-600 w-5 h-auto mr-1' />
                        <p className={`${darkMode ? 'text-green-500' : 'text-green-600'}`}>
                            Your web service has been successfully deployed
                        </p>
                    </div>
                </div>
            }
        </form>
    </div>
</>
    )
}

export default WebServiceFormX;