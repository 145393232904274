import React from "react";
import { FileText, MessageSquare, SunIcon, LifeBuoy } from "lucide-react";
import { Link } from "react-router-dom";

const Sidebar = ({ menuItems, darkMode, setDarkMode }) => {
  return (
    <nav
      className={`flex flex-col justify-between h-[calc(100vh-4rem)] ${darkMode ? "bg-gray-900 text-gray-200" : "bg-gray-100 text-gray-900"
        }`}
    >
      <div className="mt-6">
        {menuItems.map((item, index) => (
          <a
            key={index}
            onClick={item.onClick}
            className={`flex items-center px-6 py-3 cursor-pointer ${darkMode
                ? "hover:bg-gray-800 hover:text-indigo-400"
                : "hover:bg-indigo-50 hover:text-indigo-800"
              }`}
          >
            <item.icon
              className="mr-3"
              size={20}
              color={darkMode ? "#d1d5db" : "#000"}
            />
            {item.text}
          </a>
        ))}
      </div>

      <div className="border-t border-gray-300 dark:border-gray-700 py-4">
        <a
          href="mailto:hector.diaz@pucp.edu.pe"
          className={`flex items-center px-6 py-3 ${darkMode
              ? "hover:bg-gray-800 hover:text-indigo-400"
              : "hover:bg-indigo-50 hover:text-indigo-800"
            }`}
        >
          <MessageSquare
            className="mr-3"
            size={20}
            color={darkMode ? "#d1d5db" : "#000"}
          />
          Feedback
        </a>

        <a
          href="mailto:hector.diaz@pucp.edu.pe"
          className={`flex items-center px-6 py-3 ${darkMode
              ? "hover:bg-gray-800 hover:text-indigo-400"
              : "hover:bg-indigo-50 hover:text-indigo-800"
            }`}
        >
          <LifeBuoy
            className="mr-3"
            size={20}
            color={darkMode ? "#d1d5db" : "#000"}
          />
          Contact support
        </a>
{/*
        <button
          onClick={() => setDarkMode(!darkMode)}
          className={`flex items-center px-6 py-3 w-full text-left ${darkMode
              ? "hover:bg-gray-800 hover:text-indigo-400"
              : "hover:bg-indigo-50 hover:text-indigo-800"
            }`}
        >
          <SunIcon
            className="mr-3"
            size={20}
            color={darkMode ? "#d1d5db" : "#000"}
          />
          {darkMode ? "Light Mode" : "Dark Mode"}
        </button>
*/}
      </div>
    </nav>
  );
};

export default Sidebar;
