import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import logo from "../assets/no_bg_black_fino.png"
import { Flame, Terminal, Activity, Settings, BarChart3, ArrowUpDown, ArrowUpRight } from "lucide-react";
import { FileText, MessageSquare, LifeBuoy } from 'lucide-react';
import { CiGlobe } from "react-icons/ci";
import Metrics from "./deployment/metrics";
import Scale from "./deployment/scale";
import Environment from "./deployment/environment";
import Events from "./deployment/events";
import Logs from "./deployment/logs";
import { Link } from "react-router-dom";
import { collection, getDoc, onSnapshot, addDoc, updateDoc, serverTimestamp, doc } from 'firebase/firestore';

const Deployment = ({ db, darkMode, user }) => {
    const [editMode, setEditMode] = useState(false);
    const { id } = useParams();
    const [logs, setLogs] = useState([]);
    const [deleting, setDeleting] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [deploymentData, setDeploymentData] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [updatedData, setUpdatedData] = useState({
        repoUrl: '',
        webAppName: '',
        nodejsVersion: null,
        envVars: [],
        plan: '',
        portNumber: null,
        runtime: '',
        token: "",
        uid: "",
        ip: "",
        password: "",
        location: '',
        branch: null,
        deploymentId: '',
        startupCommand: ''
    });
    const [activeSection, setActiveSection] = useState('main');

    const menuItems = [
        { icon: Flame, text: "Main", onClick: () => setActiveSection('main') },
        { icon: Terminal, text: "Events", onClick: () => setActiveSection('events') },
        { icon: Activity, text: "Logs", onClick: () => setActiveSection('logs') },
        { icon: Settings, text: "Environment", onClick: () => setActiveSection('environment') },
        { icon: BarChart3, text: "Metrics", onClick: () => setActiveSection('metrics') },
        { icon: ArrowUpRight, text: "Scaling", onClick: () => setActiveSection('scaling') },
    ];

    const uid = user?.uid;

    const fetchLogs = async () => {
        try {
            const logDoc = await getDoc(doc(db, "logs", deploymentData?.data?.deploymentId));

            if (logDoc.exists()) {
                const allLogs = logDoc?.data()?.logs || [];

                const deploymentLogs = allLogs
                    .filter(log => log.userId === uid)
                    .sort((a, b) => {
                        // Handle Firestore Timestamp objects
                        const timeA = a.timestamp?.toMillis?.() || 0;
                        const timeB = b.timestamp?.toMillis?.() || 0;
                        return timeB - timeA;
                    })
                    .slice(0, 20);

                setLogs(deploymentLogs);
            } else {
                setLogs([]);
            }
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(
            doc(db, "deployments", id),
            (deploymentDoc) => {
                if (deploymentDoc.exists()) {
                    const deployment = {
                        id: deploymentDoc.id,
                        ...deploymentDoc.data()
                    };
                    setDeploymentData(deployment);
                    setUpdatedData({
                        repoUrl: deployment.data?.repoUrl || '',
                        webAppName: deployment.data?.webAppName || '',
                        nodejsVersion: deployment.data?.nodejsVersion || null,
                        envVars: deployment.data?.envVars || [],
                        plan: deployment.data?.plan || 'Beta',
                        token: deployment?.token,
                        uid: deployment?.uid,
                        ip: deployment?.ip,
                        password: deployment?.password,
                        portNumber: deployment.data?.portNumber || null,
                        runtime: deployment.data?.runtime || '',
                        location: deployment.data?.location || 'amd',
                        branch: deployment.data?.branch || null,
                        deploymentId: deployment.data?.deploymentId || '',
                        startupCommand: deployment.data?.startupCommand || ''
                    });
                } else {
                    console.error("No such deployment!");
                }
            },
            (error) => {
                console.error("Error fetching deployment:", error);
            }
        );

        return () => unsubscribe();
    }, [db, id]);

    useEffect(() => {
        let unsubscribe;

        if (activeSection === 'logs') {
            unsubscribe = onSnapshot(
                doc(db, "logs", deploymentData?.data?.deploymentId),
                (doc) => {
                    if (doc.exists()) {
                        const allLogs = doc?.data()?.logs || [];
                        const deploymentLogs = allLogs
                            .filter(log => log.userId === uid)
                            .sort((a, b) => {
                                const timeA = a.timestamp?.toMillis?.() || 0;
                                const timeB = b.timestamp?.toMillis?.() || 0;
                                return timeB - timeA;
                            })
                            .slice(0, 20);

                        setLogs(deploymentLogs);
                    } else {
                        setLogs([]);
                    }
                }
            );
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [activeSection, deploymentData?.data?.deploymentId, uid]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://${deploymentData?.endpoint}`);
        setShowTooltip(true);
    };

    useEffect(() => {
        if (showTooltip) {
            const timer = setTimeout(() => setShowTooltip(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [showTooltip]);


    const deleteDeployment = async (id) => {
            {/*
                            const subsId = deploymentData?.subscriptionId; 
            const subscriptionResponse = await fetch(`https://backend.gaia-ml.com/cancel-subscription/${subsId}`, { method: 'DELETE' });
            if (!subscriptionResponse.ok) {
                throw new Error(`Failed to cancel subscription: ${subscriptionResponse.statusText}`);
            }
                */}
        const UserId = user?.uid;
        try {
            setDeleting(true)
            const deleteInstanceResponse = await fetch(`https://backend.gaia-ml.com/delete/${id}/${UserId}`, {
                method: 'DELETE'
            });

            if (!deleteInstanceResponse.ok) {
                throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
            }
            setDeleting(false)
            window.location.href = '/dashboard'; 

        } catch (error) {
            setDeleting(false)
            console.error("Error deleting deployment:", error);
            alert(`Failed to delete deployment: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSaveChanges = async (selectedDeployment) => {
        const instanceId = selectedDeployment?.instanceId;
        const deploymentId = selectedDeployment?.data?.deploymentId;
        const uid = selectedDeployment?.uid;

        if (!instanceId || !deploymentId || !uid) {
            console.error('Missing required IDs');
            return;
        }

        const changedFields = {};
        Object.keys(updatedData).forEach(key => {
            if (updatedData[key] !== selectedDeployment.data?.[key]) {
                changedFields[key] = updatedData[key];
            }
        });

        try {
            setUpdating(true)
            const response = await fetch(`https://backend.gaia-ml.com/update-instance/${instanceId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...changedFields,
                    currentData: selectedDeployment.data,
                })
            });

            if (!response.ok) {
                throw new Error(`Failed to update instance: ${response.statusText}`);
            }

            const deploymentRef = doc(db, "deployments", selectedDeployment.id);
            await updateDoc(deploymentRef, {
                data: {
                    ...selectedDeployment.data,
                    ...changedFields
                },
                lastUpdated: serverTimestamp()
            });

            await addDoc(collection(db, "logs"), {
                userId: uid,
                deploymentId: deploymentId,
                logName: 'instance_updated',
                content: `Instance ${instanceId} updated successfully`,
                timestamp: serverTimestamp()
            });

            setDeploymentData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    ...changedFields
                }
            }));

            setEditMode(false);
            setUpdating(false)

        } catch (error) {
            setUpdating(false)
            console.error('Error saving changes:', error);
            await addDoc(collection(db, "logs"), {
                userId: uid,
                deploymentId: deploymentId,
                logName: 'update_error',
                content: `Error updating instance: ${error.message}`,
                timestamp: serverTimestamp()
            });
            throw error;
        }
    };

    return(
        <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
            <header className={`fixed top-0 left-0 right-0 ${darkMode ? 'bg-gray-900' : 'bg-white'} shadow-sm border-b z-10`}>
                <div className="mx-auto py-6 px-4 sm:px-6 lg:px-20 flex justify-between items-center">
                    <Link to="/dashboard" className="text-xl font-bold text-indigo-600 flex items-center">
                        <img className='w-20 h-auto' src={logo} alt='' />
                    </Link>
                </div>
            </header> 

            <div className="pt-24 px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row min-h-screen">
                    <nav className="w-full md:w-64 md:fixed md:left-8 md:top-24 md:bottom-8 flex-shrink-0 overflow-y-auto">
                        <div className="space-y-1 flex flex-col h-full">
                            {/* Main menu items group */}
                            <div className="flex-1">
                                {menuItems.map((item, index) => (
                                    <a
                                        key={index}
                                        onClick={item.onClick}
                                        className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors cursor-pointer ${darkMode
                                                ? 'text-gray-200 hover:bg-gray-800 hover:text-indigo-400'
                                                : 'text-gray-900 hover:bg-indigo-50 hover:text-indigo-800'
                                            }`}
                                    >
                                        <item.icon className={`mr-3 h-5 w-5 ${darkMode ? 'text-gray-400' : ''}`} />
                                        {item.text}
                                    </a>
                                ))}
                        </div>

                        {/* Bottom links group */}
                        <div className={`pt-6 mt-auto border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                            <Link
                                to="/docs"
                                className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors
                    ${darkMode
                                        ? 'text-gray-200 hover:bg-gray-800 hover:text-indigo-400'
                                        : 'text-gray-900 hover:bg-indigo-50 hover:text-indigo-800'}`}
                            >
                                <FileText className={`mr-3 h-5 w-5 ${darkMode ? 'text-gray-400' : ''}`} />
                                Docs
                            </Link>
                            <Link
                                to="/feedback"
                                className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors
                    ${darkMode
                                        ? 'text-gray-200 hover:bg-gray-800 hover:text-indigo-400'
                                        : 'text-gray-900 hover:bg-indigo-50 hover:text-indigo-800'}`}
                            >
                                <MessageSquare className={`mr-3 h-5 w-5 ${darkMode ? 'text-gray-400' : ''}`} />
                                Feedback
                            </Link>
                            <Link
                                to="/support"
                                className={`flex items-center px-4 py-3 text-sm font-medium rounded-lg transition-colors
                    ${darkMode
                                        ? 'text-gray-200 hover:bg-gray-800 hover:text-indigo-400'
                                        : 'text-gray-900 hover:bg-indigo-50 hover:text-indigo-800'}`}
                            >
                                <LifeBuoy className={`mr-3 h-5 w-5 ${darkMode ? 'text-gray-400' : ''}`} />
                                Contact support
                            </Link>
                        </div>
                </div>
            </nav>
                    <main className="flex-1 md:ml-72 py-8">
                        <div className={`${darkMode ? 'bg-gray-900' : 'bg-white'} rounded-lg shadow`}>
                            {activeSection === 'main' && (
                                <>
                                    {/* Header Section */}
                                    <div className={`px-6 py-5 border-b ${darkMode ? 'border-gray-800 bg-gray-900' : 'border-gray-200'}`}>
                                        <div className="flex justify-between items-center">
                                            <div className="flex items-center">
                                                <CiGlobe className="w-6 h-6 text-indigo-600" />
                                                <span className={`ml-2 text-lg font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>Web service</span>
                                            </div>
                                            <div className="flex space-x-4">
                                                <button
                                                    onClick={() => setEditMode(!editMode)}
                                                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
                                                >
                                                    {editMode ? 'Cancel' : 'Edit'}
                                                </button>
                                            </div>
                                        </div>
                                        <h2 className={`mt-4 text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                                            {deploymentData?.data?.webAppName}
                                        </h2>
                                    </div>

                                    {/* Details Section */}
                                    <div className="px-6">
                                        <dl className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Repository</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    <a href={deploymentData?.data?.repoUrl} target="_blank" rel="noopener noreferrer"
                                                        className={`${darkMode ? 'text-indigo-400 hover:text-indigo-300' : 'text-indigo-600 hover:text-indigo-500'}`}>
                                                        {deploymentData?.data?.repoUrl}
                                                    </a>
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Location</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    {editMode ? (
                                                        <select
                                                            name="location"
                                                            value={updatedData.location}
                                                            onChange={handleInputChange}
                                                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                                    ? 'bg-gray-700 border-gray-600 text-white'
                                                                    : 'border-gray-300 text-gray-900'
                                                                }`}
                                                        >
                                                            <option value="ams">Amsterdam (Netherlands)</option>
                                                            <option value="atl">Atlanta (Georgia, USA)</option>
                                                            <option value="ewr">New Jersey (New Jersey, USA)</option>
                                                        </select>
                                                    ) : (
                                                        <LocationDisplay locationCode={updatedData.location || deploymentData?.data?.location} />
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Plan</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    {editMode ? (
                                                        <select
                                                            name="plan"
                                                            value={updatedData.plan}
                                                            onChange={handleInputChange}
                                                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                                    ? 'bg-gray-700 border-gray-600 text-white'
                                                                    : 'border-gray-300 text-gray-900'
                                                                }`}
                                                        >
                                                            <option value="Beta">Beta</option>
                                                            <option value="Omega">Omega</option>
                                                            <option value="Delta">Delta</option>
                                                        </select>
                                                    ) : (
                                                        updatedData.plan || deploymentData?.data?.plan
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Port number</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    {editMode ? (
                                                        <input
                                                            name="portNumber"
                                                            value={updatedData.portNumber}
                                                            onChange={handleInputChange}
                                                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                                    ? 'bg-gray-700 border-gray-600 text-white'
                                                                    : 'border-gray-300 text-gray-900'
                                                                }`}
                                                        />
                                                    ) : (
                                                        updatedData.portNumber || deploymentData?.data?.portNumber
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>NodeJS version</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    {editMode ? (
                                                        <input
                                                            name="nodejsVersion"
                                                            value={updatedData.nodejsVersion}
                                                            onChange={handleInputChange}
                                                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                                    ? 'bg-gray-700 border-gray-600 text-white'
                                                                    : 'border-gray-300 text-gray-900'
                                                                }`}
                                                        />
                                                    ) : (
                                                        updatedData.nodejsVersion || deploymentData?.data?.nodejsVersion
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Branch name</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2`}>
                                                    {editMode ? (
                                                        <input
                                                            name="branch"
                                                            value={updatedData.branch}
                                                            onChange={handleInputChange}
                                                            className={`mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${darkMode
                                                                    ? 'bg-gray-700 border-gray-600 text-white'
                                                                    : 'border-gray-300 text-gray-900'
                                                                }`}
                                                        />
                                                    ) : (
                                                        updatedData.branch || deploymentData?.data?.branch
                                                    )}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Endpoint</dt>
                                                <dd className={`mt-1 text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'} sm:mt-0 sm:col-span-2 flex items-center`}>
                                                    {deploymentData?.endpoint ? (
                                                        <>
                                                            <a
                                                                className={`cursor-pointer mr-2 ${darkMode ? 'text-indigo-400 hover:text-indigo-300' : 'text-indigo-500 hover:text-indigo-600'}`}
                                                                target="_blank"
                                                                href={`https://${deploymentData?.endpoint}`}
                                                            >
                                                                https://{deploymentData?.endpoint}
                                                            </a>
                                                            <div className="relative">
                                                                <button
                                                                    onClick={copyToClipboard}
                                                                    className={`${darkMode ? 'text-gray-400 hover:text-gray-300' : 'text-gray-400 hover:text-gray-600'} focus:outline-none`}
                                                                    aria-label="Copy endpoint"
                                                                >
                                                                    <CopyIcon />
                                                                </button>
                                                                {showTooltip && (
                                                                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow-lg">
                                                                        Copied!
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : "Not available yet"}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className={`text-sm font-medium ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Delete web service</dt>
                                                <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                                                    <button
                                                        onClick={() => {
                                                            const id = deploymentData?.id;
                                                            const subscriptionId = deploymentData?.subscriptionId;
                                                            deleteDeployment(id);
                                                        }}
                                                        disabled={deleting}
                                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                    >
                                                        Delete
                                                    </button>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    {editMode && (
                                        <div className={`px-6 py-4 ${darkMode ? 'bg-gray-800' : 'bg-gray-50'} flex justify-end`}>
                                            <button
                                                onClick={() => handleSaveChanges(deploymentData)}
                                                disabled={updating}
                                                className={`px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors ${updating ? 'opacity-50 cursor-not-allowed' : ''
                                                    }`}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    )}
                                </>
                            )
                            }
                            {activeSection === 'events' && (
                                <Events db={db} darkMode={darkMode} deploymentId={deploymentData?.data?.deploymentId} userId={user?.uid} />
                            )}
                            {activeSection === 'logs' && (
                                <Logs darkMode={darkMode} logs={logs} />
                            )}
                            {activeSection === 'environment' && (
                                <Environment darkMode={darkMode} password={deploymentData.password} ip={deploymentData.hostIp} deploymentId={deploymentData?.data?.deploymentId} userId={uid} />
                            )}
                            {activeSection === 'metrics' && (
                                <Metrics darkMode={darkMode} db={db} deploymentId={deploymentData?.data?.deploymentId} userId={user?.uid} />
                            )}
                            {activeSection === 'scaling' && (
                                <Scale darkMode={darkMode} />
                            )}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Deployment;

const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
);

const EyeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
    </svg>
);

const EyeOffIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
        <line x1="1" y1="1" x2="23" y2="23"></line>
    </svg>
);

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
);

const LocationDisplay = ({ locationCode }) => {
    const locations = {
        labels: [
            "Amsterdam (Netherlands)",
            "Atlanta (Georgia, USA)",
            "New Jersey (New Jersey, USA)",
            "Paris (France)",
            "Dallas (Texas, USA)",
            "Frankfurt (Germany)",
            "Hong Kong",
            "Los Angeles (California, USA)",
            "London (United Kingdom)",
            "Manchester (United Kingdom)",
            "Miami (Florida, USA)",
            "Mexico City (Mexico)"
        ],
        options: [
            "ams",
            "atl",
            "ewr",
            "cdg",
            "dfw",
            "fra",
            "hkg",
            "lax",
            "lhr",
            "man",
            "mia",
            "mex"
        ]
    };

    const getLocationName = (code) => {
        const index = locations.options.indexOf(code);
        return index !== -1 ? locations.labels[index] : code;
    };

    return (
        <span>{getLocationName(locationCode)}</span>
    );
};