import React, { useState } from 'react';
import { Cpu, LucideMemoryStick, Server } from 'lucide-react';

const Bplans = [
    { plan: "Hobby", price: "Free for 1 month", cpu: "0.1", ram: "256MB", variantId: "531421" },
    { plan: "Type A", price: "$4/month", cpu: "0.5", ram: "512MB", variantId: "531421" },
    { plan: "Type B", price: "$18/month", cpu: "1", ram: "2GB", variantId: "531422" },
    { plan: "Type C", price: "$40/month", cpu: "2", ram: "4GB", variantId: "531423" },
    { plan: "Type D", price: "$80/month", cpu: "4", ram: "8GB", variantId: "531424" },
    { plan: "Type E", price: "$160/month", cpu: "4", ram: "16GB", variantId: "531425" }
]; 

const Scale = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    return (
        <div className="px-6 py-6">
            <div className="flex items-center gap-2">
                <Server className="w-5 h-5 text-indigo-600" />
                <h2 className="text-lg font-semibold text-gray-900">Scale</h2>
            </div>
            <div className="relative">
                <div className="mt-8">
                    <h3 className="text-lg font-semibold mb-6">Select a Plan</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Bplans.map((plan) => (
                            <div
                                key={plan.plan}
                                onClick={() => handlePlanSelect(plan)}
                                className={`border p-4 cursor-pointer transition duration-300 
                                    ${selectedPlan?.plan === plan.plan
                                        ? 'border-indigo-600 bg-indigo-50'
                                        : 'border-indigo-600 hover:bg-gray-50'
                                    }`}
                            >
                                <div className="flex justify-between">
                                    <div>
                                        <h4 className="text-base font-medium mb-1">{plan.plan}</h4>
                                        <p className="text-lg font-semibold mb-3">
                                            {plan.price}
                                            <span className="text-xs font-normal text-gray-600"></span>
                                        </p>
                                    </div>
                                    <div className="text-sm text-gray-600">
                                        <div className="flex items-center gap-1 mb-1">
                                            <Cpu className="w-4 h-4 text-indigo-600" />
                                            <p>CPU: {plan.cpu} core(s)</p>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <LucideMemoryStick className="w-4 h-4 text-indigo-600" />
                                            <p>RAM: {plan.ram}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={() => console.log('Selected plan:', selectedPlan)}
                        disabled={!selectedPlan}
                        className="mt-8 bg-indigo-600 text-white px-6 font-semibold py-2 
                            hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed 
                            transition duration-300"
                    >
                        Subscribe Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Scale;