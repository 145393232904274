import React from "react";
import { FaServer, FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import { Globe2, CheckCircleIcon } from "lucide-react";

const CronFormx = ({
    handleSubmitCron,
    cronFormData,
    handleCronFormChange,
    handleCronJobChange,
    cronJobs,
    removeCronJob,
    addCronJob,
    deploying
}) => {
    return (
        <>
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 mb-2 font-medium text-gray-900">Deploy Cron Jobs</h3>
                <p className="mb-3 mt-8 max-w-2xl text-sm text-gray-500">
                    Configure scheduled tasks for your application
                </p>
            </div>

            <form onSubmit={handleSubmitCron}>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        {[
                            {
                                label: "Deployment Name",
                                description: "Enter a unique name for your cron job deployment",
                                name: "webAppName",
                                type: "text"
                            },
                            {
                                label: "Repository URL",
                                description: "The link from your GitHub repository",
                                name: "repoUrl",
                                type: "text"
                            },
                            {
                                label: "Branch",
                                description: "Specify the branch to deploy",
                                name: "branch",
                                type: "text"
                            },
                            {
                                label: "Runtime",
                                description: "Select the runtime environment for your application",
                                name: "runtime",
                                type: "select",
                                options: ["Node"]
                            },
                            {
                                label: "Startup Command",
                                description: "Command to start your application. Example: node index.js",
                                name: "startupCommand",
                                type: "text"
                            },
                            {
                                label: "Port Number",
                                description: "The port number on which your server is running (Default is 5000)",
                                name: "portNumber",
                                type: "text"
                            },
                            {
                                label: "Location",
                                description: "Choose the server location for your deployment",
                                name: "location",
                                type: "select",
                                labels: [
                                    "US East (Virginia)",
                                    "US East (Ohio)",
                                    "US West (California)"
                                ],
                                options: ["east-us-1", "us-east-2", "us-west-1"]
                            },
                            {
                                label: "Node version",
                                description: "Select the version of Node for your application",
                                name: "nodejsVersion",
                                type: "select",
                                options: ["14.x", "16.x", "18.x", "20.x", "22.x"]
                            },
                        ].map((field, index) => (
                            <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-lg font-medium text-gray-800">
                                    {field.label}
                                    {field.description && (
                                        <p className="mt-1 text-sm font-light text-gray-700">{field.description}</p>
                                    )}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {field.type === "select" ? (
                                        <select
                                            name={field.name}
                                            value={cronFormData[field.name]}
                                            onChange={handleCronFormChange}
                                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        >
                                            {field.name === "location"
                                                ? field.labels.map((label, optionIndex) => (
                                                    <option key={optionIndex} value={field.options[optionIndex]}>
                                                        {label}
                                                    </option>
                                                ))
                                                : field.options.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                        </select>
                                    ) : (
                                        <input
                                            type={field.type}
                                            name={field.name}
                                            value={cronFormData[field.name]}
                                            onChange={handleCronFormChange}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    )}
                                </dd>
                            </div>
                        ))}
                    </dl>

                    <div className="mt-8 sm:px-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">
                            <div className="flex items-center">
                                <FaClock className="mr-2" />
                                Cron Jobs Configuration
                            </div>
                        </h3>
                        <p className="text-sm text-gray-500 mb-4">
                            Configure scheduled tasks with endpoints and cron expressions
                        </p>

                        {cronJobs.map((job, index) => (
                            <div key={index} className="mb-6 bg-gray-50 p-4 rounded-lg shadow-sm">
                                <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Endpoint</label>
                                        <input
                                            type="text"
                                            value={job.endpoint}
                                            onChange={(e) => handleCronJobChange(index, 'endpoint', e.target.value)}
                                            placeholder="/api/scheduled-task"
                                            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">Schedule (Cron Expression)</label>
                                        <input
                                            type="text"
                                            value={job.schedule}
                                            onChange={(e) => handleCronJobChange(index, 'schedule', e.target.value)}
                                            placeholder="*/5 * * * *"
                                            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                    <div className="flex items-end">
                                        <button
                                            type="button"
                                            onClick={() => removeCronJob(index)}
                                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        >
                                            <FaTrash className="h-4 w-4 mr-1" />
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <button
                            type="button"
                            onClick={addCronJob}
                            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <FaPlus className="mr-2" /> Add Cron Job
                        </button>
                    </div>
                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 mt-6">
                    <button
                        type="submit"
                        className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${deploying
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2`}
                        disabled={deploying}
                    >
                        {deploying ? (
                            <span className="flex items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Deploying...
                            </span>
                        ) : (
                            'Deploy Cron Jobs'
                        )}
                    </button>
                </div>
            </form>
        </>
    );
};

export default CronFormx;