import React, {useState, useEffect} from "react"
import { setDoc, doc} from 'firebase/firestore';
import { CheckCircleIcon } from "lucide-react";
  
const Bplans = [
    { plan: "Hobby", price: "free for 1 month", cpu: "0.1", ram: "256Mb", variantId: "531421" },
    { plan: "Tipe A", price: "4", cpu: "0.5", ram: "512 MB", variantId: "531421" },
    { plan: "Tipe B", price: "18", cpu: "1", ram: "2", variantId: "531421" },
    { plan: "Tipe C", price: "40", cpu: "2", ram: "4", variantId: "531421" },
    { plan: "Tipe D", price: "80", cpu: "4", ram: "8", variantId: "531421" },
    { plan: "Tipe E", price: "160", cpu: "4", ram: "16", variantId: "531421" },
    { plan: "Tipe F", price: "260", cpu: "8", ram: "32", variantId: "531421" },
];

const Billing = ({ user, darkMode, subscription, deleteSubscription, hasProfile, userId, auth, db }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubscribe = async () => {
        if (!selectedPlan) {
            setError('Please select a plan before subscribing.');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('https://backend.gaia-ml.com/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variantId: selectedPlan.variantId,
                    userId: user.uid,
                    userEmail: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout link');
            }

            const responseData = await response.json();
            const { checkoutUrl } = responseData;

            const docData = {
                uid: user.uid,
                response: {
                    plan: selectedPlan.plan,
                    cpu: selectedPlan.cpu,
                    ram: selectedPlan.ram,
                    variantId: selectedPlan.variantId,
                    status: response.status,
                    ok: response.ok,
                    url: response.url,
                },
                checkoutUrl: checkoutUrl,
                createdAt: new Date().toISOString()
            };

            const userRef = doc(db, 'payments_profile', userId);
            await setDoc(userRef, docData);

            window.location.href = checkoutUrl;

        } catch (error) {
            console.error('Error creating checkout link:', error);
            setError('Failed to initiate checkout. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <div className={`p-6 ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
            <h2 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Billing</h2>

            {(subscription && hasProfile) ? (
                <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} w-full sm:w-1/2 shadow-lg rounded-lg overflow-hidden`}>
                    <div className="px-6 py-8">
                        <h3 className={`font-semibold mb-6 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Your Current Subscription</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} mb-2`}>Status</p>
                                <div className='flex text-sm items-center space-x-3'>
                                    <p className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>{subscription.attributes.status_formatted}</p>
                                    <CheckCircleIcon className='w-4 h-auto text-green-600' />
                                </div>
                            </div>
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} text-sm mb-2`}>Plan</p>
                                <p className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>{subscription.attributes.user_name}</p>
                            </div>
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} mb-2`}>Invoice Date</p>
                                <p className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>{formatDate(subscription.attributes.created_at)}</p>
                            </div>
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} text-sm mb-2`}>Amount</p>
                                <p className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>{subscription.attributes.total_formatted} USD</p>
                            </div>
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} text-sm mb-2`}>Payment Method</p>
                                <p className={`text-sm ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                                    {subscription.attributes.card_brand.toUpperCase()} ending in {subscription.attributes.card_last_four}
                                </p>
                            </div>
                            <div>
                                <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'} text-sm mb-2`}>Cancel Subscription</p>
                                <button onClick={deleteSubscription} className='px-5 py-2 rounded text-sm text-white bg-red-500 hover:bg-red-600'>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-8">
                    <h3 className={`text-2xl font-semibold mb-6 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Select a Plan</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Bplans.map((plan) => (
                            <div
                                key={plan.plan}
                                className={`border p-6 rounded-lg cursor-pointer transition duration-300 ${selectedPlan?.plan === plan.plan
                                        ? darkMode ? 'border-indigo-500 bg-indigo-900/20' : 'border-indigo-500 bg-blue-50'
                                        : darkMode ? 'border-gray-700 hover:border-indigo-500 hover:bg-gray-800' : 'border-gray-200 hover:border-indigo-300 hover:bg-gray-50'
                                    }`}
                                onClick={() => setSelectedPlan(plan)}
                            >
                                <div className='flex justify-between'>
                                    <div>
                                        <h4 className={`font-medium mb-2 ${darkMode ? 'text-white' : 'text-gray-900'}`}>{plan.plan}</h4>
                                        <p className={`${darkMode ? 'text-gray-200' : 'text-gray-900'} mb-4`}>${plan.price}
                                            <span className={`text-sm font-normal ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>/month</span>
                                        </p>
                                    </div>
                                    <div className={darkMode ? 'text-gray-200' : 'text-gray-900'}>
                                        <p>CPU: {plan.cpu} core(s)</p>
                                        <p>RAM: {plan.ram} GB</p>
                                    </div>
                                </div>
                                <ul className="space-y-2">
                                </ul>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleSubscribe}
                        disabled={isLoading || !selectedPlan}
                        className="mt-8 bg-indigo-500 text-white px-6 font-semibold py-2 rounded-md hover:bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
                    >
                        {isLoading ? 'Processing...' : 'Subscribe Now'}
                    </button>
                </div>
            )}

            {error && (
                <div className={`mt-6 text-red-600 ${darkMode ? 'bg-red-900/20' : 'bg-red-100'} border ${darkMode ? 'border-red-800' : 'border-red-400'} rounded-md p-4`}>
                    {error}
                </div>
            )}
        </div>
    );
};

export default Billing;