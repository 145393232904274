import React, {useState, useEffect} from "react"
import { getAuth, signInWithPopup, GithubAuthProvider, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, deleteDoc, doc, updateDoc, arrayRemove, onSnapshot } from 'firebase/firestore';
import { Trash2, Pen } from 'lucide-react';

const AccountSettings = ({ user, darkMode, auth, db }) => {
    const [editMode, setEditMode] = useState({
        email: false,
        fullName: false,
        githubUsername: false
    });
    const [userData, setUserData] = useState({
        email: user?.email,
        fullName: user?.displayName,
        githubUsername: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setUserData(prevState => ({
                        ...prevState,
                        githubUsername: data.githubUsername || ''
                    }));
                }
            }
        };
        fetchUserData();
    }, [user]);

    const handleEdit = (field) => {
        setEditMode({ ...editMode, [field]: true });
    };

    const handleSave = async (field) => {
        setEditMode({ ...editMode, [field]: false });
        const userDocRef = doc(db, 'users', user.uid);

        try {
            if (field === 'fullName') {
                await updateProfile(auth.currentUser, { displayName: userData.fullName });
            }
            await updateDoc(userDocRef, { [field]: userData[field] });
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const handleChange = (e, field) => {
        setUserData({ ...userData, [field]: e.target.value });
    };

    const renderField = (label, field, value) => (
        <li className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className={`text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>{label}</div>
                </div>
                <div className="flex items-center">
                    {editMode[field] ? (
                        <>
                            <input
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(e, field)}
                                className={`text-sm mr-2 border rounded px-2 py-1 ${darkMode
                                        ? 'bg-gray-700 border-gray-600 text-white'
                                        : 'text-gray-500 border-gray-300'
                                    }`}
                            />
                            <button
                                onClick={() => handleSave(field)}
                                className={`${darkMode ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-800'}`}
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <>
                            <div className={`text-sm ${darkMode ? 'text-gray-300' : 'text-gray-500'} mr-2`}>{value}</div>
                            <button
                                onClick={() => handleEdit(field)}
                                className={`${darkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'}`}
                            >
                                <Pen size={16} />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </li>
    );

    return (
        <div className={`p-6 ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
            <h2 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Account Settings</h2>
            <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow overflow-hidden sm:rounded-lg`}>
                <div className="px-4 py-5 sm:px-6">
                    <h3 className={`text-lg leading-6 font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>Profile</h3>
                    <p className={`mt-1 max-w-2xl text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                        Manage your account information here.
                    </p>
                </div>
                <div className={`border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                    <ul className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                        {renderField('Contact Email', 'email', userData.email)}
                        {renderField('Full Name', 'fullName', userData.fullName)}
                        {renderField('GitHub Username', 'githubUsername', userData.githubUsername)}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AccountSettings;