import React from 'react';
import { DollarSign, Server, ExternalLink } from 'lucide-react';
import Header from "../header";
import Footer from "../footer";

const PriceComparison = ({ user, signInWithGitHub, signOutWithGitHub }) => {
    const herokuComparisons = [
        {
            tier: "Basic (512MB RAM)",
            fino: 4,
            heroku: 7,
            savings: "1.75x cheaper",
            ram: "512MB",
            cpu: "0.5 CPU"
        },
        {
            tier: "Standard (2GB RAM)",
            fino: 18,
            heroku: 50,
            savings: "5.56x cheaper (2x RAM)",
            ram: "2GB",
            cpu: "1 CPU"
        },
        {
            tier: "Performance (4GB RAM)",
            fino: 40,
            heroku: 400,
            savings: "10x cheaper",
            ram: "4GB",
            cpu: "2 CPU"
        },
        {
            tier: "Performance (8GB RAM)",
            fino: 80,
            heroku: 800,
            savings: "10x cheaper",
            ram: "8GB",
            cpu: "4 CPU"
        },
        {
            tier: "Performance (32GB RAM)",
            fino: 260,
            heroku: 3200,
            savings: "12.3x cheaper",
            ram: "32GB",
            cpu: "8 CPU"
        }
    ];

    const renderComparisons = [
        {
            tier: "Type A",
            fino: 4,
            render: 7,
            savings: "1.75x cheaper",
            ram: "512MB",
            cpu: "0.5 CPU"
        },
        {
            tier: "Type B",
            fino: 18,
            render: 25,
            savings: "1.4x cheaper",
            ram: "2GB",
            cpu: "1 CPU"
        },
        {
            tier: "Type C",
            fino: 40,
            render: 85,
            savings: "2.1x cheaper",
            ram: "4GB",
            cpu: "2 CPU"
        },
        {
            tier: "Type D",
            fino: 80,
            render: 175,
            savings: "2.2x cheaper",
            ram: "8GB",
            cpu: "4 CPU"
        },
        {
            tier: "Type E",
            fino: 260,
            render: 450,
            savings: "1.7x cheaper",
            ram: "32GB",
            cpu: "8 CPU"
        }
    ];

    const PriceTable = ({ data, competitor }) => (
        <div className="overflow-x-auto">
            <table className="min-w-full text-lg">
                <thead>
                    <tr className="border-b border-gray-600">
                        <th className="px-4 py-2 text-left text-white">RAM</th>
                        <th className="px-4 py-2 text-center text-white">Fino</th>
                        <th className="px-4 py-2 text-center text-white">{competitor}</th>
                        <th className="px-4 py-2 text-center text-white">Savings</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((comparison, index) => (
                        <tr key={index} className="border-b border-gray-700 transition-colors duration-300 hover:bg-gray-750/10">
                            <td className="px-4 py-2 text-gray-300">{comparison.ram}</td>
                            <td className="px-4 py-2 text-center text-white">
                                <div className="flex items-center justify-center gap-1">
                                    <DollarSign className="w-4 h-4 text-green-400 transition-colors duration-300" />
                                    <span className="text-green-400">{comparison.fino}</span>
                                </div>
                            </td>
                            <td className="px-4 py-2 text-center text-white">
                                <div className="flex items-center justify-center gap-1">
                                    <DollarSign className="w-4 h-4 text-red-400 transition-colors duration-300" />
                                    <span className="text-red-400">{comparison[competitor.toLowerCase()]}</span>
                                </div>
                            </td>
                            <td className="px-4 py-2 text-center">
                                <span className="px-3 py-1 rounded-full text-blue-400 font-semibold text-base">
                                    {comparison.savings}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="bg-gradient-to-br from-black via-gray-900">
            <Header user={user} signInWithGitHub={signInWithGitHub} signOutWithGitHub={signOutWithGitHub} />
            <div className="bg-gradient-to-br from-black via-gray-900 to-gray-800 min-h-screen sm:pb-64 pt-12 pb-12 px-8 sm:pt-24">
                <div className="max-w-6xl mx-auto">
                    {/* Main Heading */}
                    <div className="text-center mb-16">
                        <h1 className="text-6xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-green-400">
                            Compare Fino Pricing
                        </h1>
                        <p className="mt-4 text-2xl text-gray-400">
                            Discover the savings and performance benefits with Fino.
                        </p>
                        <p className="mt-4 text-lg text-gray-400">
                            Prices sourced from official websites:
                            <a href="https://www.heroku.com/dynos" target="_blank" rel="noopener noreferrer" className="inline-flex items-center ml-2 text-blue-300 hover:text-blue-200">
                                Heroku Pricing <ExternalLink className="w-4 h-4 ml-1" />
                            </a>
                            <span className="mx-2">and</span>
                            <a href="https://render.com/pricing#compute" target="_blank" rel="noopener noreferrer" className="inline-flex items-center text-blue-300 hover:text-blue-200">
                                Render Pricing <ExternalLink className="w-4 h-4 ml-1" />
                            </a>
                        </p>
                    </div>

                    {/* Section 1: Heroku – Table Left, Text Right */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-24">
                        {/* Table Column */}
                        <div>
                            <PriceTable data={herokuComparisons} competitor="Heroku" />
                        </div>
                        {/* Text Column */}
                        <div className="text-white space-y-4">
                            <h2 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-300 to-blue-300">
                                Fino vs Heroku
                            </h2>
                            <h3 className="text-3xl font-semibold text-white">
                                Save More, Scale Faster
                            </h3>
                            <p className="text-xl text-white">
                                Our in-depth comparison shows that Fino offers superior performance at a fraction of Heroku's price. Enjoy better resource allocation and robust scalability while cutting costs dramatically.
                            </p>
                            <p className="text-lg text-white">
                                <strong>Highlights:</strong> Enhanced performance, cost-effectiveness, and reliability designed for startups and enterprises.
                            </p>
                        </div>
                    </div>

                    {/* Section 2: Render – Text Left, Table Right */}
                    <div className="grid pt-12 grid-cols-1 md:grid-cols-2 gap-12 items-center">
                        {/* Text Column */}
                        <div className="text-white space-y-4 order-2 md:order-1">
                            <h2 className="text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-300 to-red-300">
                                Fino vs Render
                            </h2>
                            <h3 className="text-3xl font-semibold text-white">
                                Efficiency Redefined
                            </h3>
                            <p className="text-xl text-white">
                                When you compare Render with Fino, the difference is clear. Fino delivers unmatched value with lower pricing and high efficiency. Experience a smarter, more agile cloud deployment.
                            </p>
                            <p className="text-lg text-white">
                                <strong>Highlights:</strong> Seamless integrations, cost-effective scaling, and optimized performance for modern workloads.
                            </p>
                        </div>
                        {/* Table Column */}
                        <div className="order-1 md:order-2">
                            <PriceTable data={renderComparisons} competitor="Render" />
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <Footer />
            </div>
        </div>
    );
};

export default PriceComparison;