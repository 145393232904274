import React, {useState} from "react"
import {Link} from "react-router-dom"
import logo from "../assets/no_bg_fino.png"

const Header = ({ user, signInWithGitHub , signOutWithGitHub}) => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return( 
        <Link to="/" className="w-full">
            <div className="container mx-auto flex items-center py-4 px-6">
                <div className="flex text-white text-3xl w-1/3 flex justify-center items-center">
                    <img className="w-24 h-auto" src={logo} />
                </div>
                <div className="flex w-1/3 justify-evenly items-center space-x-4">
                    <a className="hidden md:flex space-x-8">
                        <Link to="/price-comparison"
                            className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                            Price comparison
                        </Link>
                    </a>
                    <a className="hidden md:flex space-x-8">
                        <Link to="/pricing"
                            className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                            Pricing
                        </Link>
                    </a>
                    <a href="mailto:hector.diaz@pucp.edu.pe"
                        className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                        Contact
                    </a>
                    <a target="_blank" href="https://www.loom.com/share/679c807fc5ed49469c820c5f7c582db6?sid=d0a46367-a1df-4639-80e2-1aa2f63bdb0d"
                        className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                        Tutorial
                    </a>
                    {user && (
                        <Link to="/dashboard"
                            className="text-[#c7b7f2] text-sm sm:flex hidden items-center hover:text-[#8e7cc3] transition-colors">
                            Dashboard
                        </Link>
                    )}
                </div>
                <div className="w-1/3 flex justify-center">
                    <button onClick={user ? signOutWithGitHub : signInWithGitHub} class="relative bg-transparent border-2 border-purple-800 border-r-blue-400 text-white px-6 py-2 rounded-full font-semibold transition-all hover:border-opacity-80">
                        {user ? "Logout" : "Login"}
                    </button>
                </div>
                <button className="md:hidden text-gray-300" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
            {mobileMenuOpen && (
                <nav className="bg-[#0a051d]/95 border-b border-gray-800">
                    <div className="px-6 py-4 space-y-2">
                        <Link to="/pricing"
                            className="block text-[#c7b7f2] text-sm hover:text-[#8e7cc3] transition-colors">
                            Pricing
                        </Link>
                        <a href="mailto:hector.diaz@pucp.edu.pe"
                            className="block text-[#c7b7f2] text-sm hover:text-[#8e7cc3] transition-colors">
                            Contact
                        </a>
                        <a target="_blank" href="https://www.loom.com/share/679c807fc5ed49469c820c5f7c582db6?sid=d0a46367-a1df-4639-80e2-1aa2f63bdb0d"
                            className="block text-[#c7b7f2] text-sm hover:text-[#8e7cc3] transition-colors">
                            Tutorial
                        </a>
                        {user && (
                            <Link to="/dashboard"
                                className="block text-[#c7b7f2] text-sm hover:text-[#8e7cc3] transition-colors">
                                Dashboard
                            </Link>
                        )}
                    </div>
                </nav>
            )}
        </Link>
    )
}

export default Header;