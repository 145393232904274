import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GithubAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { Star, Flame, Cog, DollarSign, Users, ArrowLeft, Plus, Menu, User, Github } from "lucide-react";
import { getFirestore, collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import Teams from "./teams"
import logoWhite from "../assets/no_bg_fino.png"
import Sidebar from './dashboard/sidebar';
import InstallGithub from './dashboard/installGithub';
import Elements from './dashboard/elements';
import logoBlack from "../assets/no_bg_black_fino.png"
import Billing from "./billing"
import WebServiceFormX from "./dashboard/webServiceForm"
import { FaServer, FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import AccountSettings from "./accountSettings"
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import CronFormX from './dashboard/cronsForm';
import { useNavigate } from 'react-router-dom';
import GithubConnect from './githubConnect';

const generateDeploymentId = () => {
  const now = new Date();
  return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
};


const firebaseConfig = {
  apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
  authDomain: "gammafy-638dc.firebaseapp.com",
  projectId: "gammafy-638dc",
  storageBucket: "gammafy-638dc.appspot.com",
  messagingSenderId: "849864306670",
  appId: "1:849864306670:web:506911cfb604f41628a8ff",
  measurementId: "G-74ZH9LRDP3"
};
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GithubAuthProvider();
provider.addScope('repo');

const Dashboard = ({ user, data, userPlan, darkMode, setDarkMode }) => {
  const [repositories, setRepositories] = useState([]);
  const [showList, setShowList] = useState(false);
  const [hasInstalledApp, setHasInstalledApp] = useState(false);
  const [indRepo, setIndRepo] = useState(null);
  const [main, setMain] = useState(true);
  const [details, setDetails] = useState()
  const [forward, setForward] = useState(true);
  const [deploying, setDeploying] = useState(false);
  const [githubUsername, setGithubUsername] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [tokens, setTokens] = useState()
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [showTypes, setShowTypes] = useState(true)
  const [isCleanedUp, setIsCleanedUp] = useState(false);
  const [number, setNumber] = useState(0);

  const signInWithGitHub = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const newToken = credential.accessToken;
        const signedInUser = result.user;
        setGithubUsername(signedInUser?.reloadUserInfo?.screenName)
        setToken(newToken);
        setWebServiceFormData(prevData => ({
          ...prevData,
          token: newToken
        }));
        fetchRepositories(newToken);
        setTokens(newToken)
        setShowList(true);
        setShowTypes(false)
      })
      .catch((error) => {
        console.error("Error during sign-in:", error.code, error.message);
      });
  };

  useEffect(() => {
    setNumber(data.length)
  }, [data.length])

  const fetchRepositories = async (accessToken, searchQuery = '', pageNum = 1) => {
    setLoading(true); 
    try {
      const response = await fetch(`https://api.github.com/user/repos?page=${pageNum}&per_page=99&q=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (pageNum === 1) {
        setRepositories(data);
      } else {
        setRepositories(prev => [...prev, ...data]);
      }

      setPage(pageNum);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching repositories:", error);
      setLoading(false);
    }
  };

  const searchRepositories = async (searchQuery) => {
    setLoading(true);
    try {
      const url = `https://api.github.com/search/repositories?q=${searchQuery}+user:${githubUsername}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/vnd.github.v3+json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRepositories(data.items);
    } catch (error) {
      console.error("Error searching repositories:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const plans = [
    { plan: "Hobby", price: "Free for 1 month", cpu: "0.1", ram: "256 MB" },
    { plan: "Tipe A", price: "4 USD / month", cpu: "0.5", ram: "512MB"},
    { plan: "Tipe B", price: "18 USD / month", cpu: "1", ram: "2GB" },
    { plan: "Tipe C", price: "40 USD / month", cpu: "2", ram: "4GB" },
    { plan: "Tipe D", price: "80 USD / month", cpu: "4", ram: "8GB" }
  ]

  const [webServiceFormData, setWebServiceFormData] = useState({
    token: token,
    userIdOne: user?.uid,
    deploymentId: '',
    webAppName: '',
    server_type: "",
    plan: "Beta",
    repoUrl: '',
    branch: 'main',
    runtime: 'Node',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
    buildCommand: '',
    startCommand: '',
    envVars: []
  });

  const [nameAvailability, setNameAvailability] = useState(null);
  const appNamesRef = collection(db, "appnames");

  const checkNameAvailability = async (name) => {
    if (!name) {
      return 'Please enter a name first';
    }

    try {
      const q = query(appNamesRef, where('name', '==', name));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return 'Name available!';
      } else {
        return 'Name already taken!';
      }
    } catch (error) {
      console.error('Error checking name availability:', error);
      return 'Error checking availability';
    }
  };

  const handleCheckNameAvailability = async () => {
    const result = await checkNameAvailability(webServiceFormData.webAppName);
    setNameAvailability(result);
  };

  const goBack = () => {
    setShowList(true);
    setIndRepo(null);
    setMain(true);
    setForward(true);
    setNameAvailability(null);
    setWebServiceFormData({
      token: token,
      webAppName: '',
      repoUrl: '',
      branch: 'main',
      runtime: 'Node',
      startupCommand: '',
      portNumber: "",
      location: 'ams',
      nodejsVersion: '14.x',
      envVars: []
    });
  };
  const [activeSection, setActiveSection] = useState('main');

  const handleReload = (e) => {
    e.preventDefault();
    setActiveSection('main')
    setForward(true)
    setShowTypes(true)
    setShowList(false)
    setIndRepo(null)
  };

  const menuItems = [
    { icon: Flame, text: "Deployments", onClick: (e) => handleReload(e) },
    { icon: DollarSign, text: "Billing", onClick: () => setActiveSection('billing') },
    { icon: Users, text: "Teams", onClick: () => setActiveSection('teams') },
    { icon: User, text: "Account", onClick: () => setActiveSection('user') },
    { icon: Github, text: "Github", onClick: () => setActiveSection('installGithub') }
  ];

  const stats = [
    { title: "Deployments", value: `${data?.length}` },
    { title: "Team members", value: "1" }
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [selectedType, setSelectedType] = useState(null);
  const [cronJobs, setCronJobs] = useState([{ endpoint: '', schedule: '' }]);

  const deploymentTypes = [
    { id: 'webservice', name: 'New Web Service', icon: FaServer },
    { id: 'cron', name: 'New Cron Job', icon: FaClock },
  ];

  const [deploymentType, setDeploymentType] = useState('cron');

  const handleDeploymentTypeSelect = (type) => {
    if (!token) {
      signInWithGitHub(type); 
      return;
    }

    setSelectedType(type);
    handleSelect(type);
  };

  const [cronFormData, setCronFormData] = useState({
    webAppName: '',
    repoUrl: '',
    branch: '',
    runtime: 'Node',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
  });

  const handleWebServiceFormChange = (e) => {
    setWebServiceFormData({ ...webServiceFormData, [e.target.name]: e.target.value });
  };

  const deploymentsRef = collection(db, "deployments");
  const [getResponse, setGetResponse] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowTooltip(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);
  

  const userId = user?.uid;
  const [subscription, setSubscription] = useState(null);
  const [allowed, setAllowed] = useState()
  const [subscriptionId, setSubscriptionId] = useState()
  const [instanciaId, setInstanciaId] = useState();
  const [subsId, setSubsId] = useState()

  const [hasProfile, setHasProfile] = useState(false)

  const isGithubAppInstalled = () => {
    return localStorage.getItem('githubInstalled') === 'true';
  };


  const handleGithubAppInstallation = () => {
    if (!isGithubAppInstalled()) {
      const redirectUri = encodeURIComponent(window.location.origin + '/dashboard');
      const installUrl = `https://github.com/apps/fino-cloud/installations/new?state=${redirectUri}`;
      window.location.href = installUrl;
    } else {
      console.log("GitHub App already installed.");
    }
  };


  useEffect(() => {
    if (!userId) return;
    const fetchSubscription = async () => {
      try {
        const userRef = doc(db, 'payments_profile', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setSubscription(data?.subscription);
          setAllowed(data?.response?.plan)
          setHasProfile(data.active)
          setSubsId(data?.subscription.attributes.subscription_id)
          setInstanciaId(data?.instanceId)
          setSubscriptionId(data?.subscription?.attributes?.subscription_id)
        } else {
          setHasProfile(false)
          ;}
      } catch (error) {console.error('Error fetching subscription:', error);}
    };

    fetchSubscription();
  }, [userId]);

  const handleCronFormChange = (e) => {
    setCronFormData({ ...cronFormData, [e.target.name]: e.target.value });
  };

  const handleCronJobChange = (index, field, value) => {
    const updatedCronJobs = [...cronJobs];
    updatedCronJobs[index][field] = value;
    setCronJobs(updatedCronJobs);
  };

  const addCronJob = () => {
    setCronJobs([...cronJobs, { endpoint: '', schedule: '' }]);
  };

  const removeCronJob = (index) => {
    const updatedCronJobs = cronJobs.filter((_, i) => i !== index);
    setCronJobs(updatedCronJobs);
  };

  const [cronSubmit, setCronSubmit] = useState()

  const handleSubmitCron = async (e) => {
    e.preventDefault();
    setDeploying(true);
    try {
      const response = await axios.post('https://backend.gaia-ml.com/deploy-nodejs-cron', {
        ...cronFormData,
        cronJobs,
      });
      setSuccessful(true);
      setCronSubmit(response.data);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setDeploying(false);
    }
  };

  const [showTooltipX, setShowTooltipX] = useState(false);

  useEffect(() => {
    if (showTooltipX) {
      const timer = setTimeout(() => setShowTooltipX(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltipX]);

  const handleSubmitWebService = async (e) => {
    e.preventDefault();
    setDeploying(true);
    setGetResponse(true);

    const deploymentId = generateDeploymentId();

    const envVarsString = JSON.stringify(webServiceFormData.envVars);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    try { 
      const response = await axios.post( 
        'https://backend.gaia-ml.com/deploy',
        { ...webServiceFormData, envVars: envVarsString, deploymentId}
      );
      setWebServiceFormData(prevData => ({
        ...prevData,
        deploymentId
      }));
      setDetails(response.data);
      setGetResponse(false);
      setSuccessful(true);

    } catch (error) {
      setGetResponse(false);
      console.error("Deployment Error:", error);
    } finally {
      setGetResponse(false);
      setDeploying(false);
    }
  };

  const handleSelect = (type) => {
    setSelectedType(type);
    setDeploymentType(type);
    handleDeploymentTypeSelect(type);
  };

  useEffect(() => {
    setWebServiceFormData(prev => ({
      ...prev,
      token: token
    }));
  }, [token]);

  const repoSelected = (repo) => {
    setShowList(false);
    setForward(false);
    setIndRepo(repo);
    const formData = {
      webAppName: repo.name,
      repoUrl: repo.html_url
    };
    setCronFormData(prev => ({ ...prev, ...formData }));
    setWebServiceFormData(prev => ({ ...prev, ...formData }));
  };

  const deleteSubscription = async () => {
    try {
      const deleteInstanceResponse = await fetch(`https://backend.gaia-ml.com/delete/${instanciaId}`, { method: 'DELETE' });
      if (!deleteInstanceResponse.ok) {
        throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
      }
      window.location.reload()
    }
    catch (err) {
    }
  }
 
  const [selectedDeployment, setSelectedDeployment] = useState(null);

  const navigate = useNavigate();

  const handleDeploymentClick = (deployment) => {
    navigate(`/deployment/${deployment.id}`);
  };

  const handleEnvVarChange = (index, field, value) => {
    setWebServiceFormData(prevData => {
      const newEnvVars = [...prevData.envVars];
      newEnvVars[index] = { ...newEnvVars[index], [field]: value };
      return { ...prevData, envVars: newEnvVars };
    });
  };

  const setPlan = (selectedPlan) => {
    setWebServiceFormData(prevFormData => ({
      ...prevFormData,
      plan: selectedPlan.plan,
      server_type: selectedPlan.cpu + " CPU / " + selectedPlan.ram + " RAM"
    }));
  }

  const addEnvVar = () => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: [...prevData.envVars, { key: '', value: '' }]
    }));
  };

  const removeEnvVar = (index) => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: prevData.envVars.filter((_, i) => i !== index)
    }));
  };

  const [isAvailabilityButtonDisabled, setIsAvailabilityButtonDisabled] = useState()
 
  return (
    <div className={`flex flex-col h-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} md:flex-row`}>
      <header className={`${darkMode ? "bg-gray-900 shadow-md" : "bg-white shadow-sm"} md:hidden`}>
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/dashboard" className="text-xl font-bold flex items-center">
            <img className="w-24 h-auto" src={darkMode? logoWhite : logoBlack} alt="" />
          </Link>
          <button onClick={toggleMobileMenu} className={`hidden sm:flex hover:text-gray-400 ${darkMode ? "text-gray-300" : "text-gray-500 hover:text-gray-600"}`}>
            <Menu size={24} />
          </button>
        </div>
      </header>

      <div className={`${mobileMenuOpen ? "block" : "hidden"} md:block md:w-64 ${darkMode ? "bg-gray-900 shadow-md" : "bg-white shadow-lg"}`}>
        <div className="flex items-center justify-center h-16 border-b border-gray-300 dark:border-gray-700">
          <Link to="/" className="text-xl font-bold flex items-center">
            <img className="w-20 h-auto" src={darkMode ? logoWhite : logoBlack} alt="" />
          </Link>
        </div>
        <Sidebar darkMode={darkMode} setDarkMode={setDarkMode} menuItems={menuItems} />
      </div>
      <div className="flex-1 overflow-y-auto">
        <header lassName={`${darkMode ? "bg-gray-900 shadow-md" : "bg-white shadow-sm"} hidden md:block`}>
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-end items-center">
            <div className={`w-10 h-10 flex items-center justify-center rounded-full font-bold ${darkMode ? "bg-indigo-500 text-gray-200" : "bg-indigo-600 text-white"}`}>
              <img className='rounded-full' src={user?.photoURL} alt='' />
            </div>
          </div>
        </header>
        <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {activeSection === 'main' && (
            <>
              <div className="pb-6 flex items-center">
                <p className={`text-xl ${darkMode ? "text-white" : ""} font-semibold'`}>{user?.displayName}</p>
                <button className='px-3 pt-1 font-semibold rounded-lg text-sm bg-gray-200 ml-2'>{userPlan}</button>
              </div>
              {forward && (
                <Elements darkMode={darkMode} stats={stats} showTypes={showTypes} deploymentTypes={deploymentTypes} handleSelect={handleSelect} selectedType={selectedType} data={data} handleDeploymentClick={handleDeploymentClick} />
              )}
              {showList && (
                <GithubConnect
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  repoSelected={repoSelected}
                  repos={repositories}
                  loading={loading}
                  searchRepositories={searchRepositories} 
                  selectedType={selectedType}
                  darkMode={darkMode}
                />
              )}
              {indRepo && ( 
                <div className="mt-3">
                  <button onClick={goBack} className="flex items-center text-indigo-600 hover:text-indigo-800 mb-6">
                    <ArrowLeft className="mr-2" size={20} />
                    <span className="font-semibold">Go back</span>
                  </button>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  {selectedType === "webservice" && (
                      <WebServiceFormX darkMode={darkMode} indRepo={indRepo} deploying={deploying} setPlan={setPlan} getResponse={getResponse} successful={successful} details={details} addEnvVar={addEnvVar} plans={plans} nameAvailability={nameAvailability} isAvailabilityButtonDisabled={isAvailabilityButtonDisabled} handleCheckNameAvailability={handleCheckNameAvailability} handleSubmitWebService={handleSubmitWebService} webServiceFormData={webServiceFormData} handleEnvVarChange={handleEnvVarChange} removeEnvVar={removeEnvVar} handleWebServiceFormChange={handleWebServiceFormChange} setNameAvailability={setNameAvailability} setIsAvailabilityButtonDisabled={setIsAvailabilityButtonDisabled} />
                  )}
                    {selectedType === 'cron' && (
                      <CronFormX darkMode={darkMode} handleSubmitCron={handleSubmitCron} cronFormData={cronFormData} handleCronFormChange={handleCronFormChange} handleCronJobChange={handleCronJobChange} cronJobs={cronJobs} removeCronJob={removeCronJob} addCronJob={addCronJob} deploying={deploying}  />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {activeSection === 'billing' && <Billing darkMode={darkMode} auth={auth} db={db} deleteSubscription={deleteSubscription} hasProfile={hasProfile} subscription={subscription} setSubscription={setSubscription} userId={userId} user={user} />}
          {activeSection === 'teams' && <Teams darkMode={darkMode} db={db} auth={auth} />}
          {activeSection === 'user' && <AccountSettings darkMode={darkMode} db={db} auth={auth} user={user} />}
          {activeSection === 'installGithub' && <InstallGithub darkMode={darkMode} isGithubAppInstalled={isGithubAppInstalled} handleGithubAppInstallation={handleGithubAppInstallation} />}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
