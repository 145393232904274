import React from 'react';
import logo from "../assets/no_bg_fino.png"

const Footer = () => {
    const footerLinks = {
        Product: ['Pricing', 'Demo', 'About'],
        Contact: ['Youtube', 'Twitter'],
        Legal: ['Terms of Service', 'Privacy Policy']
    };

    return (
        <footer className="bg-gradient-to-br from-black via-gray-900 to-gray-800 text-white py-12 px-6 relative overflow-hidden">
            <div className="max-w-6xl mx-auto relative z-10">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    {/* Logo Section */}
                    <div className="col-span-2 md:col-span-1 mb-8 md:mb-0">
                        <div className="flex items-center space-x-2 mb-4">
                            <img className='w-24 h-auto' src={logo} alt='' />
                        </div>
                    </div>

                    {/* Links Sections */}
                    {Object.entries(footerLinks).map(([category, links]) => (
                        <div key={category} className="flex flex-col">
                            <h3 className="text-white font-medium mb-4">{category}</h3>
                            <ul className="space-y-3">
                                {links.map((link) => (
                                    <li key={link}>
                                        <a
                                            href="#"
                                            className="hover:text-white transition-colors duration-200"
                                        >
                                            {link}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Copyright */}
                <div className="mt-12 pt-8 border-t border-gray-800">
                    <p className="text-sm text-gray-500">
                        © 2025 Fino Pte. Ltd.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;