import React from "react";
import { FaGlobe, FaCog, FaGithub, FaCopy } from "react-icons/fa";
import { Globe, Server, Package } from "lucide-react";
import {
    Star,
    Flame,
    Cog,
    DollarSign,
    Users,
    ArrowLeft,
    Plus,
    Menu,
    User,
    CheckCheck,
    CircleX,
    CheckIcon,
    LoaderIcon,
    Globe2,
    ArrowRightIcon,
    CheckCircleIcon,
    Activity,
    History,
} from "lucide-react";

const Elements = ({
    darkMode,
    stats,
    showTypes,
    deploymentTypes,
    handleSelect,
    selectedType,
    data,
    handleDeploymentClick,
}) => {
    return (
        <>
            <div className="mb-8">
                <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                    {stats.map((item, index) => (
                        <div
                            key={index}
                            className={`overflow-hidden shadow rounded-lg ${darkMode ? "bg-gray-900 text-gray-200" : "bg-white"
                                }`}
                        >
                            <div className="px-4 py-5 sm:p-6">
                                <dt
                                    className={`text-sm font-medium ${darkMode ? "text-gray-400" : "text-gray-500"} truncate`}
                                >
                                    {item.title}
                                </dt>
                                <dd
                                    className={`mt-1 text-2xl md:text-3xl font-semibold ${darkMode ? "text-gray-100" : "text-gray-900"}`}
                                >
                                    {item.value}
                                </dd>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showTypes && (
                <>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        {deploymentTypes.map((type) => (
                            <button
                                key={type.id}
                                onClick={() => handleSelect(type.id)}
                                className={`flex items-center justify-center p-4 border rounded-lg ${selectedType === type.id
                                        ? darkMode
                                            ? "bg-indigo-900 text-white border-indigo-400"
                                            : "bg-indigo-100 border-indigo-500"
                                        : darkMode
                                        ? "border-gray-700 text-white hover:border-indigo-400"
                                            : "border-gray-300 hover:border-indigo-500"
                                    }`}
                            >
                                <type.icon className={`mr-2 ${darkMode ? "text-indigo-400" : "text-indigo-600"}`}
                                    size={20}
                                />
                                <span className={`${darkMode ? "text-white" : ""}`}>{type.name}</span>
                            </button>
                        ))}
                    </div>
                    <div className="mt-8">
                        {data?.length > 0 && (
                            <h2
                                className={`text-2xl font-semibold ${darkMode ? "text-gray-100" : "text-gray-900"
                                    } mb-6`}
                            >
                                My Deployments
                            </h2>
                        )}
                        <div className="grid grid-cols-1 gap-6">
                            {data.map((element, index) => (
                                <div
                                    key={index}
                                    className={`overflow-hidden shadow rounded-lg ${darkMode ? "bg-gray-900 text-gray-200" : "bg-white"
                                        }`}
                                >
                                    <div className="px-4 py-5 sm:p-6">
                                        <div
                                            onClick={() => handleDeploymentClick(element)}
                                            className="flex items-center cursor-pointer"
                                        >
                                            <h3
                                                className={`text-lg font-medium underline truncate mb-2 ${darkMode ? "text-gray-100" : "text-gray-900"
                                                    }`}
                                            >
                                                {element?.data?.data?.webAppName}
                                            </h3>
                                            <ArrowRightIcon
                                                className={`w-6 h-auto pl-2 ${darkMode ? "text-gray-200" : "text-gray-900"
                                                    }`}
                                            />
                                        </div>
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                            <div className="sm:col-span-1 flex items-center">
                                                <dt
                                                    className={`text-sm font-medium flex items-center mb-1 ${darkMode ? "text-gray-400" : "text-gray-500"
                                                        }`}
                                                >
                                                    <Globe className="mr-2 h-5 w-5 text-indigo-500" />
                                                    Location
                                                </dt>
                                                <dd
                                                    className={`text-sm ml-2 rounded-md px-2 py-1 inline-block ${darkMode
                                                            ? "text-gray-100 bg-gray-800"
                                                            : "text-gray-900 bg-indigo-50"
                                                        }`}
                                                >
                                                    {element?.data?.data?.location}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1 flex items-center">
                                                <dt
                                                    className={`text-sm font-medium flex items-center mb-1 ${darkMode ? "text-gray-400" : "text-gray-500"
                                                        }`}
                                                >
                                                    <Server className="mr-2 h-5 w-5 text-indigo-500" />
                                                    Runtime
                                                </dt>
                                                <dd
                                                    className={`text-sm ml-2 rounded-md px-2 py-1 inline-block ${darkMode
                                                            ? "text-gray-100 bg-gray-800"
                                                            : "text-gray-900 bg-indigo-50"
                                                        }`}
                                                >
                                                    {element?.data?.data?.runtime}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1 flex items-center">
                                                <dt
                                                    className={`text-sm font-medium flex items-center mb-1 ${darkMode ? "text-gray-400" : "text-gray-500"
                                                        }`}
                                                >
                                                    <Package className="mr-2 h-5 w-5 text-indigo-500" />
                                                    Plan
                                                </dt>
                                                <dd
                                                    className={`text-sm ml-2 rounded-md px-2 py-1 inline-block ${darkMode
                                                            ? "text-gray-100 bg-gray-800"
                                                            : "text-gray-900 bg-indigo-50"
                                                        }`}
                                                >
                                                    {element?.data?.data?.plan}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1 flex items-center">
                                                <dt
                                                    className={`text-sm font-medium flex items-center mb-1 ${darkMode ? "text-gray-400" : "text-gray-500"
                                                        }`}
                                                >
                                                    <Activity className="mr-2 h-5 w-5 text-indigo-500" />
                                                    Status
                                                </dt>
                                                <dd
                                                    className={`text-sm ml-2 rounded-md px-2 py-1 inline-block ${darkMode
                                                            ? "text-gray-100 bg-gray-800"
                                                            : "text-gray-900 bg-indigo-50"
                                                        }`}
                                                >
                                                    {element?.data?.status}
                                                </dd>
                                            </div>
                                        </dl>
                                        <div className="mt-6 space-y-2">
                                            <div
                                                className={`text-sm underline flex items-center ${darkMode
                                                        ? "text-indigo-400 hover:text-indigo-300"
                                                        : "text-indigo-600 hover:text-indigo-500"
                                                    }`}
                                            >
                                                <Globe className="mr-2 h-4 w-4" />
                                                <a
                                                    href={`https://${element?.data?.endpoint}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="cursor-pointer"
                                                >
                                                    {element?.data?.endpoint
                                                        ? `https://${element?.data?.endpoint}`
                                                        : "Not available yet"}
                                                </a>
                                            </div>
                                            <a
                                                href={`${element?.data?.data?.repoUrl}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`text-sm underline cursor-pointer flex items-center ${darkMode
                                                        ? "text-gray-400 hover:text-gray-300"
                                                        : "text-gray-600 hover:text-gray-500"
                                                    }`}
                                            >
                                                <FaGithub className="mr-2 h-4 w-4" />
                                                {element?.data?.data?.repoUrl}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default Elements;
