import React from 'react';
import { Cpu, MemoryStickIcon } from 'lucide-react';
import Header from "./header"
import Footer from './footer';

const Pricing = ({ user, darkMode, signInWithGitHub, signOutWithGitHub }) => {
 
    const pricingData = [
        { tier: 'Type A', ram: '512MB', cpu: '0.5', renderPrice: '$7/month', finoPrice: '$4/month' },
        { tier: 'Type B', ram: '2GB', cpu: '1', renderPrice: '$25/month', finoPrice: '$18/month'},
        { tier: 'Type C', ram: '4GB', cpu: '2', renderPrice: '$85/month', finoPrice: '$40/month'},
        { tier: 'Type D', ram: '8GB', cpu: '4', renderPrice: '$175/month', finoPrice: '$80/month'},
        { tier: 'Type E', ram: '32GB', cpu: '8', renderPrice: '$450/month', finoPrice: '$260/month'}
    ];  
  
    return (
        <div className='bg-gradient-to-br from-black via-gray-900 to-gray-800 min-h-screen'>
            <Header user={user} signOutWithGitHub={signOutWithGitHub} signInWithGitHub={signInWithGitHub} /> 
            <div className="p-6 max-w-screen-lg pt-16 pb-32 mx-auto text-white rounded-lg">
                <div className="mb-12">
                    <div className="relative bg-gradient-to-br from-green-900/30 to-emerald-900/20 border-2 border-green-500/30 rounded-xl p-8 max-w-md mx-auto text-center backdrop-blur-sm">
                        <div className="absolute inset-0 bg-gradient-to-br from-green-500/5 to-transparent rounded-xl" />
                        <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-green-400 to-emerald-300 bg-clip-text text-transparent">
                            Hobby Plan
                        </h3>
                        <div className="space-y-3 mb-6">
                            <p className="flex items-center justify-center text-emerald-100">
                                <MemoryStickIcon className="mr-2 w-5 h-5" />
                                256MB RAM
                            </p>
                            <p className="flex items-center justify-center text-emerald-100">
                                <Cpu className="mr-2 w-5 h-5" />
                                0.1 vCPU
                            </p>
                        </div>
                        <div className="text-4xl font-bold mb-4 text-green-400">Free for 1 month</div>
                        <p className="text-sm text-green-200 mb-6 px-4">
                            Perfect for small projects and experiments. No hidden fees,
                            instant scaling, and full upgrade flexibility.
                        </p>
                        <button className="w-full bg-green-600/90 hover:bg-green-500 text-white font-semibold py-3 px-6 rounded-lg 
                          transition-all duration-300 transform hover:scale-[1.02] shadow-lg shadow-green-900/50">
                            Start Building Now
                        </button>
                    </div>
                </div>
                <h2 className="text-3xl font-bold mb-8 text-center">Fino vs Render</h2>
                <p className="mb-10 text-center text-lg">
                    Fino offers the cheapest PaaS option while being easy to use
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {pricingData.map((row, index) => (
                        <div key={index} className="bg-white/10 border border-white/20 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-xl font-semibold mb-2">{row.tier}</h3>
                            <p className="flex items-center text-white mb-2"><MemoryStickIcon className="inline-block mr-1" />{row.ram} RAM</p>
                            <p className="flex items-center text-white mb-2"><Cpu className="inline-block mr-1" />{row.cpu} CPU</p>
                            <div className="mt-4">
                                <div className="flex justify-between mb-2">
                                    <span className=" text-white font-semibold">Fino: {row.finoPrice} <a className="rounded p-1 border border-green-500 text-green-500 text-xs">Cheapest</a> </span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span className=" text-white font-semibold">Render: {row.renderPrice}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> 
            <Footer/>
</div>
    );
};

export default Pricing;  