import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { Activity, Cpu, HardDrive, Clock } from "lucide-react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MetricCard = ({ title, value, darkMode, unit, icon: Icon, color }) => (
    <div className={`rounded-lg shadow p-4 hover:shadow-md transition-shadow ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
                <div className={`p-2 rounded-lg ${color}`}>
                    <Icon className="w-5 h-5 text-white" />
                </div>
                <div>
                    <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{title}</p>
                    <p className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                        {value}
                        <span className={`text-sm font-normal ml-1 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{unit}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
);

const Metrics = ({ db, darkMode, deploymentId, userId }) => {
    const [metricsHistory, setMetricsHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!deploymentId || !userId) return;

        const unsubscribe = onSnapshot(
            doc(db, "metrics", deploymentId),
            (doc) => {
                if (doc.exists()) {
                    const allMetrics = doc.data()?.metrics || [];

                    const processedMetrics = allMetrics
                        .filter(entry => entry.userId === userId)
                        .map(entry => {
                            const containerMetrics = entry.metrics.find(
                                m => m.containerName === deploymentId
                            );

                            if (!containerMetrics) return null;

                            return {
                                timestamp: entry.timestamp.toDate(),
                                cpu: parseFloat(containerMetrics.cpuUsage.replace('%', '')) || 0,
                                memoryPercentage: parseFloat(containerMetrics.memPerc.replace('%', '')) || 0,
                                memoryUsage: parseFloat(containerMetrics.memUsage.split('MiB')[0]) || 0
                            };
                        })
                        .filter(Boolean)
                        .sort((a, b) => a.timestamp - b.timestamp);

                    setMetricsHistory(processedMetrics);
                } else {
                    setMetricsHistory([]);
                }
                setLoading(false);
            },
            (error) => {
                console.error("Error fetching metrics:", error);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, [db, deploymentId, userId]);

    if (loading) {
        return (
            <div className="px-4 md:px-6 py-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-6">Container Metrics</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8 animate-pulse">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="bg-gray-100 rounded-lg h-24"></div>
                    ))}
                </div>
                <div className="space-y-8 animate-pulse">
                    {[...Array(3)].map((_, i) => (
                        <div key={i} className="bg-gray-100 rounded-lg h-64"></div>
                    ))}
                </div>
            </div>
        );
    }

    const formatTimestamp = (timestamp) => {
        return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatTooltip = (value) => {
        if (value === undefined) return '';
        return `${value.toFixed(2)}`;
    };

    const currentMetrics = metricsHistory[metricsHistory.length - 1] || {};
    const lastUpdated = currentMetrics.timestamp ? new Date(currentMetrics.timestamp).toLocaleString() : 'N/A';

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-lg">
                    <p className="text-sm text-gray-500 mb-2">
                        {new Date(label).toLocaleString()}
                    </p>
                    {payload.map((entry, index) => (
                        <p key={index} className="text-sm" style={{ color: entry.color }}>
                            <span className="font-medium">{entry.name}:</span> {entry.value.toFixed(2)}
                            {entry.name.includes('%') ? '%' : ' MiB'}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="px-4 md:px-6 py-6">
            <div className="flex items-center justify-between mb-6">
                <h2 className={`text-xl font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>Container Metrics</h2>
                <div className={`flex items-center text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    <Clock className="w-4 h-4 mr-1" />
                    Last updated: {lastUpdated}
                </div>
            </div>

            {metricsHistory.length === 0 ? (
                <div className={`flex flex-col items-center justify-center h-64 rounded-lg border-2 border-dashed ${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-gray-50 border-gray-200'
                    }`}>
                    <Activity className={`w-8 h-8 mb-2 ${darkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                    <p className={`font-medium ${darkMode ? 'text-gray-300' : 'text-gray-500'}`}>No metrics available</p>
                    <p className={`text-sm ${darkMode ? 'text-gray-500' : 'text-gray-400'}`}>Metrics will appear here once collected</p>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
                        <MetricCard
                            title="CPU Usage"
                            value={currentMetrics.cpu?.toFixed(2)}
                            unit="%"
                            icon={Cpu}
                            color="bg-blue-500"
                            darkMode={darkMode}
                        />
                        <MetricCard
                            title="Memory Usage"
                            value={currentMetrics.memoryUsage?.toFixed(2)}
                            unit="MiB"
                            icon={HardDrive}
                            color="bg-green-500"
                            darkMode={darkMode}
                        />
                        <MetricCard
                            title="Memory Percentage"
                            value={currentMetrics.memoryPercentage?.toFixed(2)}
                            unit="%"
                            icon={Activity}
                            color="bg-red-500"
                            darkMode={darkMode}
                        />
                    </div>

                    <div className="space-y-6">
                        {/* CPU Usage Chart */}
                        <div className={`rounded-lg shadow-sm p-4 md:p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                            <h3 className={`text-sm font-medium mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>CPU Usage Over Time</h3>
                            <div className="h-48 md:h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={metricsHistory} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? '#374151' : '#f0f0f0'} />
                                        <XAxis
                                            dataKey="timestamp"
                                            tickFormatter={formatTimestamp}
                                            interval="preserveStartEnd"
                                            stroke={darkMode ? '#6B7280' : '#9ca3af'}
                                        />
                                        <YAxis domain={[0, 100]} stroke={darkMode ? '#6B7280' : '#9ca3af'} />
                                        <Tooltip content={<CustomTooltip darkMode={darkMode} />} />
                                        <Line
                                            type="monotone"
                                            dataKey="cpu"
                                            name="CPU %"
                                            stroke="#2563eb"
                                            strokeWidth={2}
                                            dot={false}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Memory Charts */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <div className={`rounded-lg shadow-sm p-4 md:p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                                <h3 className={`text-sm font-medium mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Memory Usage</h3>
                                <div className="h-48 md:h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={metricsHistory} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? '#374151' : '#f0f0f0'} />
                                            <XAxis
                                                dataKey="timestamp"
                                                tickFormatter={formatTimestamp}
                                                interval="preserveStartEnd"
                                                stroke={darkMode ? '#6B7280' : '#9ca3af'}
                                            />
                                            <YAxis stroke={darkMode ? '#6B7280' : '#9ca3af'} />
                                            <Tooltip content={<CustomTooltip darkMode={darkMode} />} />
                                            <Line
                                                type="monotone"
                                                dataKey="memoryUsage"
                                                name="Memory"
                                                stroke="#16a34a"
                                                strokeWidth={2}
                                                dot={false}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>

                            <div className={`rounded-lg shadow-sm p-4 md:p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                                <h3 className={`text-sm font-medium mb-4 ${darkMode ? 'text-white' : 'text-gray-900'}`}>Memory Usage (%)</h3>
                                <div className="h-48 md:h-64">
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart data={metricsHistory} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" stroke={darkMode ? '#374151' : '#f0f0f0'} />
                                            <XAxis
                                                dataKey="timestamp"
                                                tickFormatter={formatTimestamp}
                                                interval="preserveStartEnd"
                                                stroke={darkMode ? '#6B7280' : '#9ca3af'}
                                            />
                                            <YAxis domain={[0, 100]} stroke={darkMode ? '#6B7280' : '#9ca3af'} />
                                            <Tooltip content={<CustomTooltip darkMode={darkMode} />} />
                                            <Line
                                                type="monotone"
                                                dataKey="memoryPercentage"
                                                name="Memory %"
                                                stroke="#dc2626"
                                                strokeWidth={2}
                                                dot={false}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Metrics;