import React, {useState, useEffect} from "react"

const Logs =({ logs })=> {
    return(
        <div className="px-6 py-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Console</h2>
            <div className="relative">
                <div
                    className="bg-gray-900 rounded-lg text-white p-4 font-mono text-sm overflow-y-auto"
                    style={{ height: '400px', maxWidth: '100%' }}
                >
                    {logs?.length === 0 ? (
                        <p className="text-gray-400">No logs available.</p>
                    ) : (
                        <ul className="space-y-2">
                            {logs?.map((log) => (
                                <li key={log?.id} className="leading-relaxed">
                                    <span className="text-green-400">{log?.logName}</span>:{' '}
                                    <span className="text-gray-300">{log?.content}</span>{' '}
                                    <span className="text-gray-500 text-xs">
                                        ({new Date(log?.timestamp?.toDate()).toLocaleString()})
                                    </span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Logs;