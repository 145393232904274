import React, { useState, useEffect } from 'react';
import { Search, Github, Send, Loader2 } from 'lucide-react';

const GithubConnect = ({ repos, darkMode, searchTerm, setSearchTerm, repoSelected, loading, searchRepositories, selectedType }) => {
    const [filteredRepos, setFilteredRepos] = useState(repos);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    const handleRepoSelect = (repo) => {
        if (!selectedType) {
            alert("Please select a deployment type first");
            return;
        }
        repoSelected(repo);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchTerm]);

    useEffect(() => {
        if (debouncedSearchTerm.length > 0) {
            searchRepositories(debouncedSearchTerm);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setFilteredRepos(
            [...repos].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        );
    }, [repos]);

    function formatRelativeTime(dateString) {
        const currentDate = new Date();
        const updatedDate = new Date(dateString);
        const timeDiff = currentDate - updatedDate;

        const minute = 60 * 1000;
        const hour = 60 * minute;
        const day = 24 * hour;
        const month = 30 * day;
        const year = 365 * day;

        if (timeDiff < minute) {
            return 'less than a minute ago';
        } else if (timeDiff < 2 * minute) {
            return '1 minute ago';
        } else if (timeDiff < hour) {
            const minutes = Math.floor(timeDiff / minute);
            return `${minutes} minutes ago`;
        } else if (timeDiff < 2 * hour) {
            return '1 hour ago';
        } else if (timeDiff < day) {
            const hours = Math.floor(timeDiff / hour);
            return `${hours} hours ago`;
        } else if (timeDiff < 2 * day) {
            return '1 day ago';
        } else if (timeDiff < month) {
            const days = Math.floor(timeDiff / day);
            return `${days} days ago`;
        } else if (timeDiff < 2 * month) {
            return '1 month ago';
        } else if (timeDiff < year) {
            const months = Math.floor(timeDiff / month);
            return `${months} months ago`;
        } else if (timeDiff < 2 * year) {
            return '1 year ago';
        } else {
            const years = Math.floor(timeDiff / year);
            return `${years} years ago`;
        }
    }

    return (
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow overflow-hidden sm:rounded-lg`}>
            <div className="px-4 py-5 sm:px-6">
                <h3 className={`text-lg leading-6 font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>GitHub Repositories</h3>
                <p className={`mt-1 max-w-2xl text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>Select a repository to deploy</p>
            </div>
            <div className={`border-t ${darkMode ? 'border-gray-700' : 'border-gray-200'} px-4 py-5 sm:p-0`}>
                <div className="py-3 sm:px-6">
                    <div className="max-w-lg w-full lg:max-w-xs">
                        <label htmlFor="search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Search className={`h-5 w-5 ${darkMode ? 'text-gray-500' : 'text-gray-400'}`} aria-hidden="true" />
                            </div>
                            <input
                                id="search"
                                name="search"
                                className={`block w-full pl-10 pr-3 py-2 border rounded-md leading-5 ${darkMode
                                        ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500'
                                        : 'bg-white border-gray-300 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500'
                                    } focus:outline-none sm:text-sm`}
                                placeholder="Search repositories"
                                type="search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="flex justify-center py-8">
                        <Loader2 className="h-8 w-8 animate-spin text-indigo-600" />
                    </div>
                ) : (
                    <ul className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                        {filteredRepos.map((repo) => (
                            <li key={repo.id} className={`${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}>
                                <div
                                    onClick={() => handleRepoSelect(repo)}
                                    className="px-4 py-4 sm:px-6 cursor-pointer flex items-center justify-between"
                                >
                                    <div className="flex items-center">
                                        <Github className={`mr-4 h-6 w-6 ${darkMode ? 'text-gray-500' : 'text-gray-400'}`} />
                                        <div>
                                            <p className={`text-sm font-medium ${darkMode ? 'text-indigo-400' : 'text-indigo-600'} truncate`}>{repo.name}</p>
                                            <p className={`mt-1 text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'}`}>{repo.language || 'No language specified'}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-gray-500'} mr-4`}>{formatRelativeTime(repo.updated_at)}</p>
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${darkMode ? 'bg-green-900/30 text-green-400' : 'bg-green-100 text-green-800'
                                            }`}>
                                            {repo.visibility}
                                        </span>
                                        <button
                                            className={`ml-4 ${darkMode ? 'text-indigo-400 hover:text-indigo-300' : 'text-indigo-600 hover:text-indigo-900'}`}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRepoSelect(repo);
                                            }}
                                        >
                                            <Send className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default GithubConnect;